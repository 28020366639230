import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';

import { Router } from '@angular/router';
import { catchError, delay, map, tap, mergeMap, switchMap } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import { AuthService } from '@app/services/auth/auth.service';
import { authActions } from '@app/store/auth';
import { traitsActions } from '@app/store/traits/traits.actions';
import { TraitsApiService } from '@app/store/services/traits-api.service';
import { TraitsService } from '@app/services/traits/traits.service';

@Injectable()
export class TraitsEffects {

    getTraits$ = createEffect(
        () => this.actions.pipe(
            ofType(traitsActions.getTraits),
            switchMap((action: any) => {
                return from(this.traitsApiService.getTraits(action.epeCode, action.langCode)).pipe(
                    map((traits: any) => {
                        if (traits && traits.items) {
                            traits = traits.items;
                            this.traitService.setTraitListDate(action.epeCode);
                            this.traitService.setTraitList(traits, action.epeCode);
                        }
                        return traitsActions.setTraits({ traits });
                    }),
                    catchError((err) => {
                        let errorMessage = err;
                        if (err.statusText === 'Unknown Error') {
                            errorMessage = 'The server is offline, try it again later.';
                        }
                        if (err) {
                            errorMessage = 'The username or password is incorrect';
                        }
                        return of(traitsActions.setTraitsError({ errorMessage, ettCode: action.ettCode }));
                    })
                );
            })
        )
    );

    setLocalTraits$ = createEffect(
        () => this.actions.pipe(
            ofType(traitsActions.getLocalSavedTraits),
            switchMap((action: any) => {
                return from(this.traitService.getTraitList(action.epeCode)).pipe(
                    map((traits: any) => {
                        return traitsActions.setTraits({ traits });
                    }),
                );
            })
        )
    );

    logOut$ = createEffect(
        () => this.actions.pipe(
            ofType(authActions.logOut),
            tap(() => {
                this.authService.resetAll();
                this.router.navigateByUrl('/login');
            })), { dispatch: false }
    );

    public checkLocalSavedTraits() {
        return true;
    }

    constructor(private actions: Actions,
                private router: Router,
                private traitsApiService: TraitsApiService,
                private authService: AuthService,
                private traitService: TraitsService) {
    }
}
