import { Action, createReducer, on } from '@ngrx/store';
import { traitsActions } from '@app/store/traits/traits.actions';
import { initialTraitState } from '@app/store/traits/traits.state';

export const TraitsReducer = createReducer(
    initialTraitState,

    on(traitsActions.getTraits, (state) => {
        return ({ ...state, traitsList: { ...state.traitsList, isLoading: true, hasError: false, errorMessage: null, tests: null } });
    }),

    on(traitsActions.getLocalSavedTraits, (state) => {
        return ({ ...state, traitsList: { ...state.traitsList, isLoading: true, hasError: false, errorMessage: null, tests: null } });
    }),

    on(traitsActions.setTraits, (state, { traits }) => {
        return ({ ...state, traitsList: { ...state.traitsList , list: traits, isLoading: false }});
    }),

    on(traitsActions.setTrait, (state, { testTraits }) => {
        return ({ ...state, currentTest: { ...state.currentTest , traits: testTraits.traits, test: testTraits.test, isLoading: false }});
    }),

    on(traitsActions.setTraitsError, (state, { errorMessage }) => {
        return ({ ...state, currentTest: { ...state.currentTest, hasError: true, errorMessage, isLoading: false }});
    }),

    on(traitsActions.logOut, (state, action) => {
        return initialTraitState;
    }),
);

export function reducer(state, action) {
    return TraitsReducer(state, action);
}
