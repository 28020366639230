import { createFeatureSelector, createSelector } from '@ngrx/store'
import { ITraitState } from '@app/store/traits/traits.state';

export const traitsState = createFeatureSelector<ITraitState>('traits');

export const selectTraitsState = createSelector(
  traitsState,
  (state: ITraitState) => state
)

export const getTraits = createSelector(
  traitsState,
  (state: ITraitState) => state.currentTest.traits
)

export const getTestTraits = createSelector(
  traitsState,
  (state: ITraitState) => state.traitsList.list
)
