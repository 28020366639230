export interface ITraitState {
    currentTest: {
        traits: any[],
        test: string,
        isLoading: boolean;
        hasError: boolean;
        errorMessage: string;
    },
    traitsList: {
        list: any[],
        isLoading: boolean;
        hasError: boolean;
        errorMessage: string;
    },
}

export const initialTraitState: ITraitState = {
    currentTest: {
        traits: null,
        test: null,
        isLoading: false,
        hasError: false,
        errorMessage: null
    },
    traitsList: {
        list: null,
        isLoading: false,
        hasError: false,
        errorMessage: null
    }
};
