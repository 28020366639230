import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { AbstractApiService } from '@app/services/abstract-api.service';
import { AppState } from '@app/store';
import { Store } from '@ngrx/store';
import { throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TraitsApiService extends AbstractApiService {

    private TRAITS_URL = '/traits/';

    constructor(protected _http: HttpClient, protected store: Store<AppState>) {
        super(store, _http);
    }

    public getTraits(epeCode, langCode): any {
        return this.get(`${this.TRAITS_URL + epeCode}/${this.langCodePathMapping.get(langCode)}` ).pipe(
            map((res) => {
                if (res) {
                    if (res.success === 'true') {
                        return res;
                    }
                    if (res.success === 'false') {
                        throw throwError(res.success);
                    }
                }
            }),
            catchError((error) => {
                throw error;
            })
        );
    }
}
